import React, { Component } from "react";
import Question from "../Question";
import ProgressGrid from "../ProgressGrid";
import DiscoverMore from "../DiscoverMore";
import Button from "../Button";
import s from "./style.module.css";
import cn from "classnames";
import Observer from "react-intersection-observer";
import { ReactComponent as Confetti } from "../../svgs/confetti.svg";
import { ReactComponent as Facebook } from "../../svgs/facebook.svg";
import { ReactComponent as Twitter } from "../../svgs/twitter.svg";
import { ReactComponent as LinkedIn } from "../../svgs/linkedin.svg";

const objToHash = (obj) =>
  Object.keys(obj)
    .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
    .join("&");

const URL = "https://thepathtoequality.org/";
const tweetText =
  "Every day, women in many countries and economies still face barriers to earning and controlling their own money. But what barriers? Where in the world? And how big is the economic cost of this inequality?";

const social = [
  {
    name: "twitter",
    baseUrl: "https://twitter.com/share",
    params: {
      url: URL,
      text: tweetText,
    },
  },
  {
    name: "facebook",
    baseUrl: "https://www.facebook.com/sharer/sharer.php",
    params: {
      u: URL,
    },
  },
  {
    name: "linkedin",
    baseUrl: "https://www.linkedin.com/shareArticle",
    params: {
      url: URL,
      mini: true,
    },
  },
];

class Section extends Component {
  hasGuessed() {
    this.setState({ hasGuessed: true });
  }

  constructor(props) {
    super(props);
    this.state = {
      hasGuessed: false,
    };

    this.hasGuessed = this.hasGuessed.bind(this);
  }

  render() {
    const {
      id,
      title,
      copy,
      question,
      answer,
      img,
      insights,
      text,
      source,
      progressSource,
      includeSource,
      cta,
      ctaSub,
      gridSize,
      hasUsedSlider,
      hideInsights,
      discover,
      onSlider,
      ...rest
    } = this.props;
    return (
      <div className={s.wrap}>
        <Observer>
          {({ inView, ref }) => (
            <div className={cn(s.section, s.test)} ref={ref}>
              <div className={s.inner}>
                <div className={s.image}>
                  <img id={img} src={require(`../../svgs/${img}.svg`)} alt="" />
                </div>
                {title && <h2 className={s.title}>{title}</h2>}
                {copy && <p className={s.copy}>{copy}</p>}
                {text && (
                  <div className={s.copy}>
                    <div dangerouslySetInnerHTML={{ __html: text }} />
                    {cta && (
                      <div className={s.cta}>
                        <p className={cn({ [s.isVisible]: inView })}>
                          <span className={s.confetti}>
                            <Confetti />
                          </span>
                          <span className={s.confetti}>
                            <Confetti />
                          </span>
                          {cta}
                        </p>
                        <p>{ctaSub}</p>
                      </div>
                    )}
                  </div>
                )}
              </div>

              {question && (
                <Question
                  question={question}
                  answer={answer}
                  shown={inView}
                  hasGuessed={this.hasGuessed}
                  hasUsedSlider={hasUsedSlider}
                  onSlider={onSlider}
                  {...rest}
                />
              )}

              {insights && !hideInsights && (
                <ProgressGrid
                  items={insights}
                  hasGuessed={this.state.hasGuessed}
                  source={progressSource}
                  gridSize={img}
                />
              )}

              {cta && (
                <div className={s.share}>
                  <p>Share this on…</p>
                  <p>
                    <span className={s.shareButton}>
                      <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        tag="a"
                        href={`${social[1].baseUrl}?${objToHash(
                          social[1].params
                        )}`}
                      >
                        Facebook <Facebook />
                      </Button>
                    </span>
                    <span className={s.shareButton}>
                      <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        tag="a"
                        href={`${social[0].baseUrl}?${objToHash(
                          social[0].params
                        )}`}
                      >
                        Twitter <Twitter />
                      </Button>
                    </span>
                    <span className={s.shareButton}>
                      <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        tag="a"
                        href={`${social[2].baseUrl}?${objToHash(
                          social[2].params
                        )}`}
                      >
                        LinkedIn <LinkedIn />
                      </Button>
                    </span>
                  </p>
                </div>
              )}

              {cta && <DiscoverMore items={discover} />}
            </div>
          )}
        </Observer>
        {source && (
          <p
            className={s.source}
            dangerouslySetInnerHTML={{ __html: source }}
          />
        )}
      </div>
    );
  }
}

export default Section;
