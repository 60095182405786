import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { treemap, hierarchy } from "d3-hierarchy";
import objectToArray from "../../../utils/objectToArray";

const TreeMap = ({
  text,
  isVisible,
  data,
  width,
  hasExternalLabel = [],
  alignBottom = [],
}) => {
  const dataAsArray = objectToArray(data);

  var map = treemap().size([100, 50]).padding(0).round(true);

  const root = hierarchy({
    children: dataAsArray,
  });

  const tree = map(
    root.sum(function (d) {
      return d.value;
    })
  );

  return (
    <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
      <p className={s.title}>{text}</p>

      <div className={s.map}>
        {tree.children.map(({ x0, x1, y0, y1, value, data }, i) => (
          <div
            key={i}
            className={cn(s.node, {
              [s.hasExternalLabel]: hasExternalLabel[i],
              [s.alignBottom]: alignBottom[i],
              [s.isSouthAsia]: data.key === "South Asia",
            })}
            style={{
              left: `${x0}%`,
              top: `${y0 * 2}%`,
              width: `${x1 - x0}%`,
              height: `${(y1 - y0) * 2}%`,
              transitionDelay: `${i * 0.05}s`,
            }}
          >
            <span
              className={s.text}
              style={{
                transitionDelay: `${i * 0.05 + 0.1}s`,
              }}
            >
              <span className={s.value}>{value}</span> {data.key}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TreeMap;
