export default (object) => {
  const array = [];
  for (var k in object) {
    if (object.hasOwnProperty(k)) {
      array.push({ key: k, value: object[k] });
    }
  }

  return array;

  // console.log(object);
  // return Object.entries(object).map(([key, value]) => ({ key, value }));
};
