export const trackEvent = (
  eventCategory,
  eventAction,
  eventLabel,
  eventValue
) => {
  if (typeof window.ga === "function") {
    window.ga("send", {
      hitType: "event",
      eventCategory,
      eventAction,
      eventLabel,
      eventValue
    });
  }
};
