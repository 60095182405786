import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import objectToArray from "../../../utils/objectToArray";

const BarChart = ({ title, max = 190, data = [], isVisible }) => {
  const test = objectToArray(data);
  return (
    <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
      <p className={s.title}>{title}</p>

      <div>
        {test.map(({ value, key: text }, i) => (
          <div key={i} className={s.barWrap}>
            <div
              className={s.bar}
              style={{
                transform: `translateX(${(value / max) * 100}%)`,
                transitionDelay: `${i * 0.1}s`,
              }}
            >
              <strong
                className={s.value}
                style={
                  {
                    // transitionDelay: `${0.5 + i * 0.1}s`
                  }
                }
              >
                {value}
              </strong>{" "}
              {text}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BarChart;
