import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const LinkBox = ({ title, link, sub, icon = "arrow" }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={cn(s.wrap)}
  >
    <div className={s.text}>
      <p className={s.title}>
        {title}
        &nbsp;
        {icon === "download" && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="15"
            height="15"
            className={s.arrow}
          >
            <path
              fill="currentColor"
              d="M382.6 233.4A16 16 0 0 0 368 224h-64V16a16 16 0 0 0-16-16h-64a16 16 0 0 0-16 16v208h-64a16 16 0 0 0-12 26.5l112 128a16 16 0 0 0 24 0l112-128a16 16 0 0 0 2.6-17.1z"
            />
            <path
              fill="currentColor"
              d="M432 352v96H80v-96H16v128a32 32 0 0 0 32 32h416a32 32 0 0 0 32-32V352h-64z"
            />
          </svg>
        )}
        {icon === "arrow" && (
          <svg
            className={cn(s.arrow, s.arrowRight)}
            viewBox="0 0 10 15"
            width="10"
            height="15"
            style={{
              overflow: "visible",
            }}
          >
            <path
              d="m 5,1 L5,14"
              stroke="currentColor"
              fill="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
            <path
              d="m1,9 L 5,14 L9,9"
              stroke="currentColor"
              fill="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
            />
          </svg>
        )}
      </p>
    </div>
  </a>
);

export default LinkBox;
