import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const LinkBox = ({ title, link, sub, icon }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className={cn(s.wrap)}
  >
    <div className={s.text}>
      <p className={s.title}>
        {title}
        &nbsp;
        <svg
          className={s.arrow}
          viewBox="0 0 10 15"
          width="10"
          height="15"
          style={{
            overflow: "visible",
          }}
        >
          <path
            d="m 5,1 L5,14"
            stroke="currentColor"
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="m1,9 L 5,14 L9,9"
            stroke="currentColor"
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </p>
      <p className={s.sub}>{sub}</p>
    </div>
  </a>
);

export default LinkBox;
