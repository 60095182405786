import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import LinkBox from "./LinkBox";

const DiscoverMore = ({ items = [] }) => (
  <div className={cn(s.wrap)}>
    <h3 className={s.title}>Discover More</h3>
    <div className={cn(s.grid)}>
      {items.map(options => (
        <div className={cn(s.gridItem)} key={options.title}>
          <LinkBox {...options} />
        </div>
      ))}
    </div>
  </div>
);

export default DiscoverMore;
