import React, { Component } from "react";
import Slider from "./Slider";
import Button from "../Button";
import s from "./style.module.css";
import debounce from "lodash.debounce";
import { trackEvent } from "../../utils/analytics";

const getId = (text) => text.toLowerCase().replace(/ /g, "-");

const getAnswer = (guess, triggers) => {
  const [, aLittleLow, correct, , wayTooHigh] = triggers.split(",");

  if (guess <= +aLittleLow) {
    return "wayTooLow";
  }

  if (guess < +correct) {
    return "aLittleLow";
  }

  if (guess === +correct) {
    return "correct";
  }

  if (guess < +wayTooHigh) {
    return "aLittleHigh";
  }

  return "wayTooHigh";
};

class Question extends Component {
  checkAnswer(e) {
    e.preventDefault();
    this.setState({ hasGuessed: true }, this.props.onSlider);
    trackEvent(
      "Question Slider",
      "Guessed",
      getId(this.props.question),
      this.state.guess
    );
    this.props.hasGuessed();
  }

  updateGuess(e) {
    this.setState({ guess: +e.target.value }, this.props.onSlider);
  }

  getAnswerText(guess, answer) {}

  constructor(props) {
    super(props);

    this.state = {
      guess: 0,
      hasGuessed: false,
    };

    this.updateGuess = this.updateGuess.bind(this);
    this.debouncedGuess = debounce(this.updateGuess, 5, {
      leading: true,
      trailing: true,
    });
    this.checkAnswer = this.checkAnswer.bind(this);
  }

  render() {
    const {
      question,
      answer,
      buttonText = "See the answer",
      shown,
      hasUsedSlider,
    } = this.props;

    const { guess, hasGuessed } = this.state;

    return (
      <div className={s.bg}>
        <div className={s.wrap}>
          <h3 className={s.question}>
            <label htmlFor={getId(question)}>{question}</label>
          </h3>
          <p className={s.prompt}>
            Move the slider to guess, then check the answer.
          </p>
          <Slider
            id={getId(question)}
            guess={guess}
            answer={answer}
            onChange={(e) => {
              e.persist();
              this.debouncedGuess(e);
            }}
            showAnswer={hasGuessed}
            className={s.slider}
            shown={shown}
            hasUsedSlider={hasUsedSlider}
          />

          {!hasGuessed && (
            <Button className={s.button} onClick={this.checkAnswer}>
              {buttonText}
            </Button>
          )}

          {hasGuessed && (
            <div className={s.answer}>
              <p>{this.props[getAnswer(guess, this.props.triggers)]}</p>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default Question;
