import React, { Component } from "react";
import Header from "./components/Header";
import Section from "./components/Section";
import data from "./config/all.json";

class App extends Component {
  constructor() {
    super();
    this.state = {
      hasUsedSlider: false
    };

    this.setSliderUsed = this.setSliderUsed.bind(this);
  }

  setSliderUsed() {
    this.setState({
      hasUsedSlider: true
    });
  }

  render() {
    return (
      <div>
        <Header />

        {data.sections.map((section, i) => (
          <Section
            key={i}
            {...section}
            hasUsedSlider={this.state.hasUsedSlider}
            onSlider={this.state.hasUsedSlider ? () => {} : this.setSliderUsed}
          />
        ))}
      </div>
    );
  }
}

export default App;
