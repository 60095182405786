import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import objectToArray from "../../../utils/objectToArray";

const DoubleBarChart = ({ title, max = 100, data = [], isVisible }) => {
  const dataAsArray = objectToArray(data);

  return (
    <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
      <p className={s.title}>{title}</p>

      <div>
        <div className={s.datum}>
          <div className={s.key}>
            Paternity <br />
            2020 <span className={s.example} /> 2009
          </div>
          <div className={cn(s.text, s.smallText)} />
          <div className={s.key}>
            Maternity <br />
            2009 <span className={cn(s.example, s.exampleReversed)} /> 2020
          </div>
        </div>

        <div className={s.datum}>
          <div className={s.key}>
            <div className={s.ticks}>
              <span>100%</span>
              <span>0%</span>
            </div>
          </div>
          <div className={s.text} />
          <div className={s.key}>
            <div className={s.ticks}>
              <span>0%</span>
              <span>100%</span>
            </div>
          </div>
        </div>

        {dataAsArray.map(({ value, key: text }, i) => (
          <div className={s.datum} key={i}>
            <div className={s.bars}>
              <div className={s.barWrap}>
                <div
                  className={s.bar}
                  style={{
                    width: `${(value[1] / max) * 100}%`,
                    transitionDelay: `${i * 0.1}s`,
                  }}
                />
              </div>
              <div className={s.barWrap}>
                <div
                  className={s.bar}
                  style={{
                    left: `${(Math.min(value[0], value[1]) / max) * 100}%`,
                    width: `${(Math.abs(value[1] - value[0]) / max) * 100}%`,
                    transitionDelay: `${i * 0.1 + 0.5}s`,
                  }}
                />
              </div>
            </div>
            <div className={s.text}>{text}</div>
            <div className={s.bars}>
              <div className={s.barWrap}>
                <div
                  className={s.bar}
                  style={{
                    width: `${(value[3] / max) * 100}%`,
                    transitionDelay: `${i * 0.1}s`,
                  }}
                />
              </div>
              <div className={s.barWrap}>
                <div
                  className={cn(s.bar, {
                    [s.barReversed]: value[2] > value[3],
                  })}
                  style={{
                    left: `${(value[2] / max) * 100}%`,
                    width: `${(Math.abs(value[3] - value[2]) / max) * 100}%`,
                    transitionDelay: `${i * 0.1 + 0.5}s`,
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DoubleBarChart;
