import React, { Component } from "react";
import { forceSimulation, forceManyBody, forceCollide } from "d3-force";
import { forceAttract } from "d3-force-attract";
import s from "./style.module.css";
import debounceRender from "react-debounce-render";

class DotsCanvas extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dots: new Array(190)
        .fill(undefined)
        .map(() => ({ x: 85, y: 50, opacity: 0.1 })),
    };

    this.getSimulation = this.getSimulation.bind(this);
    this.update = this.update.bind(this);
  }

  getSimulation() {
    const simulation = forceSimulation(this.state.dots);

    this.attract = forceAttract()
      .target((d, i) => [i >= this.props.onLeft ? 85 : 15, 50])
      .strength(0.8);

    this.updateCanvas = true;
    return simulation
      .force("charge", forceManyBody().strength(1.2))
      .force("collide", forceCollide(2.1).strength(0.15))
      .force("fill", () => {
        const nodes = this.simulation.nodes();
        for (var i = 0; i < nodes.length; i++) {
          const onLeft = i >= this.props.onLeft;

          if (!nodes[i].start) {
            nodes[i].start = performance.now();
          }

          if (nodes[i].onLeft !== onLeft) {
            nodes[i].currentOpacity = nodes[i].opacity;
            nodes[i].nextOpacity = onLeft ? 0.2 : 1;
            nodes[i].start = performance.now();
          }

          const elapsed = Math.abs(performance.now() - nodes[i].start);
          const t = Math.min(1, elapsed / 1000);
          nodes[i].opacity =
            nodes[i].currentOpacity * (1 - t) + nodes[i].nextOpacity * t;
          nodes[i].onLeft = onLeft;
        }
      })
      .force("attract", this.attract)
      .alpha(0.01)
      .alphaDecay(0)
      .on("tick", (dots) => {
        if (this.updateCanvas) {
          this.update();
          this.updateCanvas = false;
        } else {
          this.updateCanvas = true;
        }
      })
      .stop();
  }

  update(t) {
    const ctx = this.canvas.getContext("2d");
    ctx.clearRect(0, 0, 1000, 1000);

    const nodes = this.state.dots;

    ctx.fillStyle = "#346F6F";
    for (var i = 0; i < nodes.length; i++) {
      const node = nodes[i];

      if (ctx.globalAlpha !== node.opacity) {
        ctx.globalAlpha = node.opacity;
      }

      ctx.beginPath();
      ctx.arc(
        Math.floor(node.x * 10),
        Math.floor(node.y * 10),
        window.innerWidth < 600 ? 6 : 4,
        0,
        Math.PI * 2,
        true
      );
      ctx.fill();
    }

    nodes.forEach(({ x, y, region }, i) => {});
  }

  componentWillUpdate(nextProps) {
    if (this.props.onLeft !== nextProps.onLeft) {
      this.attract.target((d, i) => [i >= nextProps.onLeft ? 85 : 15, 50]);
    }

    if (this.props.shown !== nextProps.shown) {
      if (nextProps.shown) {
        this.simulation.restart();
      } else {
        this.simulation.stop();
      }
    }
  }

  componentDidMount() {
    this.simulation = this.getSimulation();
  }

  render() {
    return (
      <div className={s.wrap}>
        <canvas
          width="1000"
          height="1000"
          className={s.canvas}
          ref={(elem) => (this.canvas = elem)}
        />
      </div>
    );
  }
}

export default debounceRender(DotsCanvas, 1000 / 60, { leading: true });
