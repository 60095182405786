import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Trophy from "../../../svgs/trophy.svg";

const TextBox = ({ text, isCelebrating, isVisible }) => (
  <p
    className={cn(s.wrap, {
      [s.isCelebrating]: isCelebrating,
      [s.isVisible]: isVisible
    })}
  >
    {isCelebrating && <img className={s.icon} src={Trophy} alt="" />}
    <span dangerouslySetInnerHTML={{ __html: text }} />
  </p>
);

export default TextBox;
