import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Single = ({
  text,
  number,
  newsTitle = "Good news!",
  news,
  isVisible,
  noGoodNews,
  isSecond,
}) => (
  <div className={cn(s.single, { [s.isSecond]: isSecond })}>
    <p className={s.num}>{number}</p>
    <p className={s.text}>{text}</p>
  </div>
);

const NumberBox = ({ first, second, isVisible, noGoodNews }) => (
  <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
    <div className={s.dots}>
      {Array.apply(0, Array(first.number)).map((i, n) => (
        <div
          key={n}
          className={s.dot}
          style={{
            transitionDelay: `${n * 0.05}s`,
          }}
        />
      ))}
    </div>
    <Single {...first} isVisible={isVisible} />
  </div>
);

export default NumberBox;
