import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import Button from "../Button";
import AgainstYourWill from "../../svgs/header.svg";

const scrollIn = (id) =>
  document.getElementById(id).scrollIntoView({
    behavior: "smooth",
    block: "start",
  });

const Header = ({ isVisible }) => (
  <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
    <div className={s.image}>
      <img src={AgainstYourWill} alt="" />
    </div>
    <div className={s.dots}>
      {Array(6)
        .fill()
        .map((_, i) => (
          <div
            key={i}
            className={s.dot}
            style={{
              animationDelay: `${Math.random() * (i % 2 ? -25 : -50)}s`,
              animationDuration: `${Math.random() * 15 + 10}s`,
              top: `${Math.random() * 33.33 + (i % 3) * 33.33}%`,
            }}
          />
        ))}
    </div>
    <h1 className={s.title}>
      Breaking barriers: <br /> the path to equality
    </h1>
    <div className={s.intro}>
      <p>
        Women have been disproportionately impacted by COVID-19. And gender
        equality is critical to recovery from the pandemic. Yet women in many
        countries still face barriers throughout their working lives. But what
        barriers? Where? And how big is the economic cost?
      </p>
      <p>Let's imagine you're a young woman who's just finished school…</p>
    </div>
    <p>
      <Button onClick={scrollIn.bind(this, "going-places")}>
        Start{" "}
        <svg
          className={s.icon}
          viewBox="0 0 10 15"
          width="10"
          height="15"
          style={{
            overflow: "visible",
          }}
        >
          <path
            d="m 5,1 L5,14"
            stroke="currentColor"
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
          <path
            d="m1,9 L 5,14 L9,9"
            stroke="currentColor"
            fill="transparent"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
          />
        </svg>
      </Button>
    </p>
  </div>
);

export default Header;
