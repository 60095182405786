import React from "react";
import s from "./style.module.css";
import DotsCanvas from "../DotsCanvas";
import Prompt from "../../Prompt";
import cn from "classnames";

const Slider = ({
  id,
  min = 0,
  max = 190,
  onChange = () => {},
  guess,
  shown,
  answer,
  showAnswer,
  hasUsedSlider,
  className,
}) => {
  const value = showAnswer ? answer : guess;

  return (
    <div
      className={cn(s.wrap, {
        [s.hasGuessed]: showAnswer,
        [className]: className,
      })}
    >
      <div className={s.controls}>
        <p
          className={cn(s.countryDetail, {
            [s.countryDetailVisible]: !hasUsedSlider,
          })}
        >
          Each dot is one country
          <svg viewBox="0 0 100 50" width="50">
            <path
              stroke="#000"
              strokeWidth="2"
              d="M 0,50 Q 50,0 100,50"
              fillOpacity="0"
            />
            <path
              stroke="#000"
              strokeWidth="2"
              d="M 100,30 L100,50 L80,50"
              fillOpacity="0"
            />
          </svg>
        </p>
        <DotsCanvas onLeft={value} shown={shown} />
        <div className={s.slideWrap}>
          <div className={s.fakeTrackWrap}>
            <div
              className={s.fakeTrack}
              style={{
                transform: `translateX(${(value / max) * 100 - 100}%)`,
              }}
            />
          </div>
        </div>

        <input
          id={id}
          disabled={showAnswer}
          className={s.slider}
          type="range"
          min={min}
          max={max}
          step={1}
          onChange={onChange}
          value={guess || 0}
        />
        <div className={s.guess}>
          <span
            className={cn(s.outputWrap, s.outputWrapSlow)}
            style={{
              transform: `translateX(${Math.round((value / max) * 100)}%)`,
            }}
          >
            <span className={s.prompt}>
              <Prompt isVisible={!hasUsedSlider} />
            </span>
            <span className={s.guessText}>{value}</span>
          </span>
          <span
            className={s.outputWrap}
            style={{
              transform: `translateX(${Math.round((value / max) * 100)}%)`,
            }}
          >
            <span className={s.guessBlob} />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
