import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const TreeMap = ({ text, isVisible, src }) => {
  return (
    <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
      <p className={s.title}>{text}</p>

      <div className={s.map}>
        <img src={src} alt="" />
      </div>
    </div>
  );
};

export default TreeMap;
