import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import TextBox from "./TextBox";
import BarChart from "./BarChart";
import NumberBox from "./NumberBox";
import CountryBox from "./CountryBox";
import TreeMap from "./TreeMap";
import LinkBox from "./LinkBox";
import Image from "./Image";
import DoubleBarChart from "./DoubleBarChart";
import Observer from "react-intersection-observer";

const ProgressGrid = ({
  hasGuessed,
  items = [],
  includeSource,
  hasSmallGrid,
  gridSize = "grid3",
  source,
}) => (
  <div className={cn(s.wrap, { [s.isVisible]: hasGuessed })}>
    <h3 className={s.title}>Insights</h3>
    <div className={cn(s.grid, { [s[gridSize]]: gridSize })}>
      {items.map(({ size, type, options }, i) => (
        <Observer triggerOnce key={i}>
          {({ inView, ref }) => (
            <div ref={ref} className={s.gridItem}>
              {type === "textBox" && (
                <TextBox {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "barChart" && (
                <BarChart {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "numberBox" && (
                <NumberBox {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "countryBox" && (
                <CountryBox {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "treeMap" && (
                <TreeMap {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "image" && (
                <Image {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "doubleBarChart" && (
                <DoubleBarChart {...options} isVisible={inView && hasGuessed} />
              )}
              {type === "linkBox" && (
                <LinkBox {...options} isVisible={inView && hasGuessed} />
              )}
            </div>
          )}
        </Observer>
      ))}
    </div>
    {source && <p className={s.source}>{source}</p>}
  </div>
);

export default ProgressGrid;
