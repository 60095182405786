import React from "react";
import s from "./style.module.css";
import cn from "classnames";
import { ReactComponent as Confetti } from "../../../svgs/confetti.svg";

const Single = ({
  text,
  number,
  newsTitle = "Good news!",
  news,
  isVisible,
  noGoodNews,
  isSecond,
}) => (
  <div className={cn(s.single, { [s.isSecond]: isSecond })}>
    <p className={s.num}>{number}</p>
    <p className={s.text}>{text}</p>
    {!noGoodNews && (
      <p className={s.news}>
        <span className={s.confetti}>
          <Confetti />
        </span>
        <span className={s.confetti}>
          <Confetti />
        </span>
        {newsTitle}
        <span className={s.mainNews}>{news}</span>
      </p>
    )}
  </div>
);

const NumberBox = ({ first, second, isVisible, noGoodNews }) => (
  <div className={cn(s.wrap, { [s.isVisible]: isVisible })}>
    <Single {...first} isVisible={isVisible} noGoodNews={noGoodNews} />
    {second && (
      <Single
        {...second}
        isVisible={isVisible}
        isSecond
        noGoodNews={noGoodNews}
      />
    )}
  </div>
);

export default NumberBox;
