import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Button = ({
  tag: Tag = "button",
  onClick,
  children,
  className,
  ...rest
}) => (
  <Tag
    className={cn(s.button, { [className]: className })}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Tag>
);

export default Button;
