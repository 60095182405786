import React from "react";
import s from "./style.module.css";
import cn from "classnames";

const Arrow = () => (
  <svg
    viewBox="0 0 20 10"
    width="30"
    height="15"
    style={{
      overflow: "visible"
    }}
  >
    <path
      d="m1,1 L 5,5 L1,9"
      stroke="currentColor"
      fill="transparent"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="3"
    />
  </svg>
);

const Prompt = ({ isVisible }) => (
  <span className={cn(s.wrap, { [s.isVisible]: isVisible })}>
    <span className={s.arrow}>
      <Arrow />
    </span>
    <span className={s.arrow}>
      <Arrow />
    </span>
    <span className={s.arrow}>
      <Arrow />
    </span>
  </span>
);

export default Prompt;
